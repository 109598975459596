import styled from 'styled-components';
import { FlexRow } from '@components/wrapper';

export const Wrapper = styled.div<{ isMobile }>`
  width: 100%;
  height: ${(props) => (props.isMobile ? 40 : 36)}px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.gnbBg};
  border-top: ${(props) => (props.isMobile ? `1px solid ${props.theme.colors.gnbDivder}` : 'none')};
  overflow-x: overlay;

  @supports (overflow: overlay) {
    overflow-y: overlay;
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
      width: 0;
      border: solid #ffffff 0px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(136, 136, 138);
    }
  }
`;
export const Container = styled(FlexRow)<{ isMobile: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
  gap: ${(props) => (props.isMobile ? '20px' : '56px')};
`;

export const CategoryButton = styled.div`
  cursor: pointer;
`;

export const CommonLi = styled.div<{ isActive?: boolean }>`
  position: relative;
  height: 100%;
  display: inherit;
  align-items: inherit;
  ${(props) => props.isActive && 'color: #313135;'}
`;

export const DevideLineContainer = styled.div<{ isMobile?: boolean }>`
  display: inherit;
  align-items: inherit;
  color: ${(props) => (props.isMobile ? '#88888A' : '#313135')};
`;

export const DevideLine = styled.div<{ isMobile?: boolean }>`
  width: 1px;
  height: ${(props) => (props.isMobile ? 16 : 20)}px;
  margin-top : ${(props) => (props.isMobile ? 2 : 1)}px;
`;
